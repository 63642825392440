<template>
  <div ref="LanguageSelection" :class="['language-selection transparent flex', { 'scrolled': isScrolled }]">
    <div class="language-selection-container flex">
      <a
        v-for="lang in $store.state.Global.languages"
        :class="[ 'language-selection-container-cta flex', { active: $store.state.Global.currentLanguage === lang } ]"
        :key="`languageCta-${ lang }`"
        :href="languageRoute(lang)"
        :title="$t(`languageSelection.${ lang }Data`)"
      >
        <img
          :class="{ active: $store.state.Global.currentLanguage === lang }"
          :src="`/img/languages/${ lang }Flag.png`"
          :alt="$t(`languageSelection.${ lang }Data`)"
        >
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LanguageSelection',
  computed: {
    /*
     * @computed - isScrolled
     * True is window scrollY is bigger or equal to 10
     */
    isScrolled() {
      return this.$store.state.Global.window.scroll >= 10;
    },
    /*
     * @computed - languageRoute
     * Returns a url to match the anchor language with pathname and current active vue route if exists
     */
    languageRoute() {
      return lang => {
        let languageRoute = null;

        if (this.$store.state.Global.currentLanguage !== lang) { // If lang is not active
          const { origin, pathname } = window.location;
          let baseUrl;

          if (lang === this.$utils.defaultLanguage) { // Spanish
            const theOtherLang = this.$store.state.Global.languages.filter(language => language !== lang).join('');

            baseUrl = origin + pathname.split(`/${ theOtherLang }`).join('');
          } else { // English
            baseUrl = `${ origin }/${ lang + pathname }`;
          }

          languageRoute = baseUrl + (this.$route ? `#${ this.$route.path }` : '');
        }

        return languageRoute;
      };
    }
  }
};
</script>

<style lang="scss" scoped>
  .language-selection {
    @include boxShadow(0.65, true, false);
    background-color: $dark-purple;
    height: 3rem;
    padding: 0;
    position: fixed;
    top: 4.25rem;
    width: 100%;
    z-index: 710;
    transition: background-color 1.5s ease, box-shadow 1.5s ease, height 0.5s ease, top 1s ease;

    @include breakpoint($small) {
      height: 3.25rem;
      top: 5rem;
    }

    @include breakpoint($large) {
      height: 3.75rem;
      top: 5.75rem;
    }

    &.scrolled {
      @include boxShadow(0.35, true, false);
      background-color: rgba($dark-purple, 0.5);
      height: 2.25rem;
      top: 2.25rem;

      @include breakpoint($small) {
        height: 2.5rem;
        top: 3rem;
      }

      @include breakpoint($large) {
        height: 3rem;
        top: 3.75rem;
      }
    }

    &-container {
      height: 100%;
      margin: auto 0.5rem auto auto;
      position: relative;

      &-cta {
        height: 100%;
        padding: 0 0.5rem;

        &:hover img:not(.active) {
          @include bounceBlurTransition(1.2, 0.1rem);
        }

        &.active {
          background-color: $white-gray;
          pointer-events: none;
        }

        img {
          margin: auto;
          max-height: 0.75rem;

          &.active {
            @include boxShadow(0.35, true, false);
            max-height: 1.25rem;
          }
        }
      }
    }
  }
</style>
